<script setup lang="ts">
  import { ModalContent } from '@/interfaces';
  import { StructureModule } from '@/interfaces';

  import Message from './message/index.vue';

  const { t } = useI18n();

  const props = defineProps<{
    inputs: StructureModule;
    content: ModalContent;
  }>();
 
  const isMessageAction = props.content.action === 'delete' || !props.inputs.inputs.length;
</script>

<template>
  <div class="modal--container fz14">
    <suspense>
      <template #default>
        <common-structure v-if="!isMessageAction" :module="inputs"></common-structure>
        <message
          v-else
          :action="content.action"
          :message="content.message"
          :data="inputs.data"
        ></message>
      </template>
      <template #fallback>
        <span>{{ t('components.common.loading') }}</span>
      </template>
    </suspense>
  </div>
</template>

<style scoped lang="scss">
.modal--container {
  flex: 1 1 auto;
}
</style>
