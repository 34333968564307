<script setup lang="ts">
import { apiModal } from '@/services';
import { initialInputs } from './initial/inputs';
import { initialContent } from './initial/content';
import { modalRequests } from './initial/requests';
import { useEmitter } from '@/use/emitter';
import { useNotificationStore } from '@/stores/notification';
import type { ApiMethodParams } from '@/utils/axios/wrapper';
import type { ModalMain, ModalInitial, StructureModule } from '@/interfaces';

import ModalTitle from './title/index.vue';
import ModalContainer from './container/index.vue';
import ModalActions from './actions/index.vue';

const { t } = useI18n();

const emitter = useEmitter();
const modal = useModalStore();
const globalStore = useGlobalStore();
const notification = useNotificationStore();

const state = reactive({
  url: undefined as string | undefined,
  options: {
    content: initialContent(modal.modal as ModalInitial),
    inputs: { ...initialInputs(modal.modal as ModalInitial), options: { modal: true } },
  } as ModalMain,
});

const options_width = '458px';

const destroyModal = () => {
  modal.destroy();
};

const keyHandler = (event: KeyboardEvent) => {
  if (
    event.key === 'Enter' &&
    !event.shiftKey &&
    !document.querySelector('.common-calendar > .calendar')
  )
    return submitHandler();
  if (event.key === 'Escape') return destroyModal();
};

const collectMainOptions = (): ApiMethodParams => ({
  method: state.options.content.method,
  url: `${state.options.content.request}${state.options.content.method === 'delete' ? `/${state.options.inputs?.data._id}` : ''
    }`,
  data: state.options.content.method !== 'delete' && state.options.inputs?.data,
});

const collectFileOptions = (formData: FormData, _id: string): ApiMethodParams => ({
  method: state.options.content.fileMethod,
  url: state.options.content.fileRequest || '',
  data: formData,
  requestConfig: {
    params: { _id },
    headers: { 'Content-Type': 'multipart/form-data' },
  },
});

const mainRequest = () => {
  const options = collectMainOptions()

  return apiModal.submit(options)
    .then(response => {
      if (response.message)
        notification.create({ message: response.message, type: 'info' });

      if (state.options.content.emit) {
        const action = state.options.content.id.split('-').pop();
        emitter.emit(state.options.content.emit, { data: response.data, action: action });
      }

      return response.data.url ? response.data : response.data._id;
    })
    .catch(err => {
      console.error(err)
    })
    .finally(() => {
      globalStore.setIsPending(false);
    })
};

const fileTypesRequest = (_id: string) => {
  const inputs = state.options.inputs as StructureModule;
  const types = inputs.inputs.filter((input) => ['qrcode'].includes(input.type));
  const datas = ['qrcode'];

  const isInput = types.some((type) => {
    if (Array.isArray(inputs.data[type.id])) {
      return inputs.data[type.id].length;
    } else {
      return inputs.data[type.id];
    }
  });
  const isData = datas.some((type) => {
    return inputs.data[type];
  });

  if (!isInput && !isData) return;

  const formData = new FormData();
  formData.append('_id', _id);

  if (isData) for (const type of datas) formData.append(type, inputs.data[type]);

  const options = collectFileOptions(formData, _id);
  apiModal.submit(options)
    .then(response => {
      emitter.emit(state.options.content.fileEmit || state.options.content.emit || '', {
        data: response.data,
        action: 'file',
      })
    })
};

const manipulationData = () => {
  const data = state.options.inputs?.data;
  if (!data) return;
};

const submitHandler = () => {
  globalStore.setIsPending(true)

  const check = verifyInputs(state.options.inputs as StructureModule)

  if (!check.status) {
    globalStore.setIsPending(false)
    notification.create({
      message: state.options.content.rejectInput || t('components.common.errors.requiredAll'),
      type: 'warning',
    })

    return
  }

  manipulationData()

  mainRequest()
    .then(main => {
      if (main.url) {
        state.url = main.url
      } else {
        modal.destroy()
      }
      if (state.options.content.fileRequest) fileTypesRequest(main)
    })
}

modalRequests(state.options);

onMounted(() => {
  document.addEventListener('keyup', keyHandler)
})

onUnmounted(() => {
  document.removeEventListener('keyup', keyHandler)
})
</script>

<template>
  <section class="overlay flex-c" @click="destroyModal">
    <div class="overlay-modal flex-col" @click.stop>
      <modal-title v-if="state.options.content.title" :title="(state.options.content.title as string)"
        :description="state.options.content.description" :type="state.options.content.id"></modal-title>
      <modal-container v-if="state.options.inputs" :inputs="state.options.inputs"
        :content="state.options.content"></modal-container>
      <modal-actions v-if="state.options.content.request" :pending="globalStore.getIsPending" :url="state.url"
        :submit-name="state.options.content.submitName" :cancel-name="state.options.content.cancelName"
        @submit-handler="submitHandler" @cancel-handler="destroyModal"></modal-actions>
    </div> 
  </section> 
</template>

<style scoped lang="scss">
.overlay {
  background: rgba(0, 0, 0, 0.6);
  @apply fixed top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden z-20;

  &-modal {
    max-width: 90%;
    width: v-bind('options_width');

    @apply my-10 relative rounded-xl bg-white p-6 gap-8;
  }
}
</style>
